import React from "react";
import HeaderSection from "../../../Components/HeaderSection/HeaderSection";
import Text from "../../../Components/Text/Text";

import "../Styles/PressRelease.css";
import ImageComp from "../../../Components/ImageComp/ImageComp";
import MyButton from "../../../Components/Button/Button";
import Footer from "../../../Components/Footer/Footer";
import Navbar from "../../../Components/Navbar/Navbar";
import { Helmet } from "react-helmet";
import EachPressRelease from "../Components/EachPressRelease/EachPressRelease";

export default function HamidNawazPressRelease() {
  return (
    <div className="PressRelease">
      <Helmet>
        <title>Hamid Nawaz Press Release</title>
      </Helmet>
      <EachPressRelease
        PressReleaseHeadLine="Hamid Nawaz Joins DynaSys Networks as Chairman of the Board"
        PresReleaseDate="Tue 25 Jul, 2023"
        PressReleaseHeader="With over 26 years of global IT, Telecoms and Satellite Communications experience, Hamid Nawaz brings unique expertise, all-round high energy, and time-tested leadership to DynaSys Networks."
        PressReleaseDetailsText1="<span>25<sup>th</sup> July 2023 – Dubai, UAE</span> – DynaSys Networks announced today that Mr. Hamid Nawaz has joined the organization as a Shareholder and Director. He has also been elected as Chairman of the Board.Prior to joining DynaSys, Hamid was spearheading the business in Middle East and Central Asia for SES, the Luxembourg based satellite operator. Hamid has also held leadership roles at Supernet, the Pakistan based systems integrator and service provider, where he was the COO and at National Data Base and Registration Authority (NADRA), one of Pakistan’s largest database management institutions, where he was the Head of Networks. Hamid also has a background in research and development in waveform analysis and transmission systems for the defence industry.Hamid has been the recipient of Pakistan’s National IT Excellence Award from the Prime Minister of the country."
        PressReleaseImage={"/Images/hamidNawazImg.webp"}
        PressReleaseDetailsText2="“I am absolutely thrilled to welcome Hamid to DynaSys Networks. I have known him for thirteen, very productive, years. His extensive exposure to the IT & Telecommunications industries in the region coupled with a highly sought after skillset for GEO and non-GEO satellite networks further strengthens our growth strategy. With all his experiences and relationships within the international space and telecommunications industries, he will be playing a key leadership role in realizing DynaSys’ ambitions for its customers, shareholders, team, and business partners”, stated Ali Akhtar, Founder & CEO, DynaSys Networks."
        PressReleaseDetailsText3="“DynaSys Networks from its inception has geared itself to provide high quality services in the areas of satellite communications and cybersecurity to the satellite operators and service providers, enterprises, MNOs, and defence entities. And now DynaSys has also taken initiatives to develop and materialize business cases for IOT in Pakistan and MENA. IOT in Pakistan especially is at an exciting juncture. And I look forward to helping DynaSys ideate, innovate, and implement and become the best version of itself”, says Hamid Nawaz, Chairman of the Board, DynaSys Networks."

      />

      <div class="borderSec">
        <div class="border"></div>
      </div>
        
    </div>
  );
}
