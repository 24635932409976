import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Text from "../../Components/Text/Text";
import "../../Styles/Landing.css";
import MyButton from "../../Components/Button/Button";
import ImageComp from "../../Components/ImageComp/ImageComp";
import SolutionSlider from "../../Components/SolutionSlider/SolutionSlider";
import Footer from "../../Components/Footer/Footer";
import { HashLink } from "react-router-hash-link";
import NewsSlider from "../../Components/NewsSlider/NewsSlider";
import { Helmet } from "react-helmet";

export default function Landing() {
  const [videoReady, setVideoReady] = useState(false);

  useEffect(() => {
    const videoElement = document.getElementById("headerVideo");

    const handleVideoReady = () => {
      setVideoReady(true);
    };

    if (videoElement) {
      videoElement.addEventListener("canplaythrough", handleVideoReady);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("canplaythrough", handleVideoReady);
      }
    };
  }, []);

  return (
    <div className="Landing">
      <Helmet>
        <title>DynaSys Networks - Ideate. Innovate. Implement</title>
      </Helmet>
      {!videoReady && (
        <div id="preloader">
          <div class="preloaderbor">
            <div class="spinner"></div>
            <div id="preloader-inner" class="preloadercontent">
              <ImageComp Src="DynaSysLoadermdpi.png" Width="60%" />
            </div>
          </div>
        </div>
      )}

      {/* Landing page header section */}
      <div className="headerSec">
        <div className="headerText">
          <Text
            text="A Realm of <span> Boundless <br> Opportunities </span>"
            Color="white"
            FontSize="4rem"
            FontWeight="700"
          />
          <Text
            text="Let’s come together and <br> discover more..."
            Color="white"
            Margin="15px 0px 25px 0px"
            FontSize="2rem"
            FontWeight="500"
          />
          <HashLink smooth to="/#SolutionSlider">
            <MyButton
              text="Our Solutions"
              Margin="0px 50px 0px 0px"
              Color="white"
              FontSize="1.5rem"
              FontWeight="600"
            />
          </HashLink>
        </div>
        <div className="headerImage">
          <video
            id="headerVideo"
            width="320"
            height="240"
            playsInline
            loop
            autoPlay
            muted
            preload="auto"
          >
            <source src="Images/headervideoblack1.mp4" type="video/mp4" />
          </video>
        </div>
      </div>

      {/* Press Release section */}
      <NewsSlider />

      {/* Dynasys Network Section */}

      <div className="DynasysNetworkSec" id="aboutus">
        <div className="DynasysNetworkSecHead">
          <Text
            text="About Us"
            FontSize="2.5rem"
            FontWeight="500"
            Color="black"
            Margin="50px 0px 35px 0px"
          />
        </div>
        <div className="dunasysnetworksText">
          <Text
            text="Dynasys Networks"
            FontSize="3rem"
            FontWeight="600"
            Color="white"
          />
          <Text
            text="Ideate | Innovate | Implement"
            FontSize="2rem"
            Margin="8px 0px 15px 0px"
            Color="white"
          />
        </div>
        <div className="DynasysNetworkImg">
          <ImageComp Src="/Images/DynasysNetwork.webp" Width="100%" />
          <div className="dunasysnetworksText2">
            <Text
              text="Dynasys Networks"
              FontSize="3rem"
              FontWeight="600"
              Color="white"
            />
            <Text
              text="Ideate | Innovate | Implement"
              FontSize="2rem"
              Margin="15px 0px 55px 0px"
              Color="white"
            />
          </div>
        </div>
        <div className="DynasysNetworkBtnSec">
          <div className="whoWeareBtn">
            <MyButton
              RouteLink="/whoweare"
              text="Who We Are"
              Color="white"
              FontSize="2rem"
              FontWeight="400"
            />
          </div>
          <div className="leaderBtn">
            <MyButton
              RouteLink="/leadership"
              text="Our Leadership"
              Color="white"
              FontSize="2rem"
              FontWeight="400"
            />
          </div>
        </div>
      </div>

      {/* Solution Slider */}
      <div className="SolutionSlider" id="SolutionSlider">
        <SolutionSlider />
      </div>

      <div className="borderSec">
        <div className="border"></div>
      </div>

      {/* Verticals Section */}
      <div className="VerticalsSecHead" id="Verticals">
        <Text
          text="Verticals"
          FontSize="2.5rem"
          FontWeight="500"
          Margin="50px 0px 35px 0px"
        />
      </div>
      <div className="VerticalsSec">
        <div className="verticaleactItem">
          <ImageComp Src="/Images/Telecoms.webp" Width="100%" />
          <Text text="Telecoms" Margin="15px 0px 15px 0px" />
        </div>

        <div className="verticaleactItem">
          <ImageComp Src="/Images/Defence.webp" Width="100%" />
          <Text text="Defence" Margin="15px 0px 15px 0px" />
        </div>

        <div className="verticaleactItem">
          <ImageComp Src="/Images/Enterprise.webp" Width="100%" />
          <Text text="Enterprise" Margin="15px 0px 15px 0px" />
        </div>

        <div className="verticaleactItem">
          <ImageComp Src="/Images/Government.webp" Width="100%" />
          <Text text="Government" Margin="15px 0px 15px 0px" />
        </div>
      </div>
      <div className="borderSec">
        <div className="border"></div>
      </div>
      {/* Footer */}
        
    </div>
  );
}
