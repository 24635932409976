import React from "react";
import HeaderSection from "../../Components/HeaderSection/HeaderSection";
import PageDescription from "../../Components/PageDescription/PageDescription";
import ImageComp from "../../Components/ImageComp/ImageComp";
import Text from "../../Components/Text/Text";
import Footer from "../../Components/Footer/Footer";
import SolutionSliderInnerPages from "../../Components/SolutionSliderInnerPages/SolutionSliderInnerPages";
import Navbar from "../../Components/Navbar/Navbar";
import { Helmet } from "react-helmet";
import ThreeColumnWrapper from "../../Components/ThreeColumnWrapper/ThreeColumnWrapper";

export default function Satellites() {
  return (
    <div className="Satellite Communications">
      <Helmet>
        <title>Satellite Communications</title>
      </Helmet>
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            BannerText="Satellite <br> Communications "
            Src="/Images/SatellitesBanner.webp"
            text="Solutions <span>/</span>"
            text1="Satellite Communications"
            RouteLink="/#SolutionSlider"
            RouteLink1="/satellites"
            Color1="#6C6C6C"
            Color2="black"
          />

          <PageDescription
            text="Satellite <br> Communications"
            text1="Experience transnational coverage with our extensive satellite network ensuring connectivity in even the most remote and challenging environments worldwide. Wherever your operations take you, we have you covered."
          />
          <ThreeColumnWrapper
            ImgSrc1={"/Images/satellite.webp"}
            Col1Text={
              "<span>Satellite Capacity and Capabilities</span> <br> <br> With a robust and scalable satellite infrastructure, we deliver high-performance connectivity and data transmission capabilities. <br><br> We are equipped with state-of-the-art technology, providing reliable and secure connections for mission-critical operations. <br><br>From voice and data to video and IoT applications, we empower businesses with the capacity to transmit and receive data efficiently."
            }
            Col2Text={
              "<span>Satellite Network Infrastructure</span> <br> <br> Benefit from our vast network infrastructure that combines the power of numerous satellites, teleports, and cutting-edge technology. <br><br> We maintain a diverse range of satellites in our network, strategically positioned to ensure seamless coverage across the globe. <br><br> Our satellite network is designed to provide exceptional performance, reliability, and scalability for your business needs."
            }
          />
          <ThreeColumnWrapper
            ImgSrc2={"/Images/satellite2.webp"}
            Col1Text={
              "<span>Benefits of Satellite Connectivity</span> <br> <br> Reliability is at the core of satellite connectivity, making it an ideal solution for businesses operating in remote or challenging environments. <br><br> With satellite services from DynaSys Networks, trust in robust and consistent connectivity that keeps your operations running smoothly. <br><br>Enjoy the advantages of global reach, ensuring connectivity wherever your business takes you."
            }
            Col2Text={
              "<span>Our Expertise and Support</span> <br> <br> We pride ourselves on our deep industry knowledge and technical expertise in satellite services. <br><br> Our team of experts is dedicated to understanding your unique requirements and providing tailored solutions that meet your business goals. <br><br> Count on our 24/7 support and the experience of our field engineers, who are deployed globally to ensure the highest level of service."
            }
          />

          {/* Solution Slider */}
          <div className="SolutionSlider">
            <SolutionSliderInnerPages />
          </div>
        </div>
      </div>

      <div className="borderSec">
        <div className="border"></div>
      </div>
    </div>
  );
}
