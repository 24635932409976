import React from 'react'
import { Helmet } from 'react-helmet'
import Navbar from '../../../Components/Navbar/Navbar'
import HeaderSection from '../../../Components/HeaderSection/HeaderSection'
import Text from '../../../Components/Text/Text'
import ImageComp from '../../../Components/ImageComp/ImageComp'
import MyButton from '../../../Components/Button/Button'
import Footer from '../../../Components/Footer/Footer'
import "../Styles/DisruptXPressRelease.css"

export default function DisruptXPressRelease() {
  return (
    <div className='DisruptXPressRelease'>
      <Helmet>
  <title>Disrupt-X and DynaSys Networks Press Release</title>
</Helmet>
    <Navbar />
    <div className="Page_Body">
    <div className="Page_Body_wraper">
    <HeaderSection
        text="Media Center   <span>/</span>"
        text1="  Press Releases"
        // RouteLink="/press_releases/disrupt_x_press_release"
        RouteLink1="/press_releases"
        Color1="#6C6C6C"
        Color2="black"
      />
      <div className="DistuptPressReleaseBody">
      <div className="Top_Text">
      <Text
          text="Disrupt-X and DynaSys Networks Announce Innovative Partnership to Catalyze IoT Rollout in Pakistan"
          FontWeight="900"
          FontSize={"3.5rem"}
          font
          Margin="50px 0px 50px 0px"
        />
        <Text
          text="Tue 08 Aug, 2023"
          FontWeight="500"
          Color="#808080"
          FontSize={"1.8rem"}
          font
          Margin="50px 0px 20px 0px"
        />
        <Text
          text="Disrupt-X partners with DynaSys Networks to fast-track IoT adoption, marking a significant leap in Pakistan's digital landscape."
          FontSize={"3.2rem"}
          Margin="0px 0px 50px 0px"
          Padding="0px 150px 0px 0px"
        />
      </div>
        
        <div className='Disrupt_Pres_image_sec'>
            <div className='Disrupt_Pres_image_sec_Left'>
                <div className='Disrupt_Pres_image_sec_Left_Text'>
                    <Text text="Reshaping" FontWeight={"900"} FontSize={"3.5rem"} LineHeight={"1"} />
                    <Text text="the Future" FontWeight={"900"} FontSize={"3.5rem"} Color={"#808080"} LineHeight={"1"} />
                    <Text text="Fast Track IoT adoption" FontWeight={"500"} FontSize={"1.6rem"} Color={"#808080"} LineHeight={"1"}  Margin={"15px 0px 0px 0px"}/>

                </div>
                <div className='Disrupt_Pres_image_sec_Left_Image'>
                    <ImageComp Src={"/Images/disruptpressimage1.webp"} />
                    <ImageComp Src={"/Images/disruptpressimage2.webp"}  Margin={"0px 0px 0px 35px"}/>
                </div>
                <div className='MobDisrupt_Pres_image_sec_Left_Image'>
                    <ImageComp Src={"/Images/Mobdisruptpressimage1.webp"} />
                    <ImageComp Src={"/Images/Mobdisruptpressimage2.webp"}  Margin={"0px 0px 0px 35px"} />
                </div>
            </div>
            
            <div className='Disrupt_Pres_image_sec_right'>
            <ImageComp Src={"/Images/disruptpressimage3.webp"} Width="100%" />
            </div>
            <div className='MobDisrupt_Pres_image_sec_right'>
            <ImageComp Src={"/Images/Mobdisruptpressimage3.webp"} Width="100%" />
            </div>
            <div className='Mob_Disrupt_Pres_image_sec_Left_Text'>
                    <Text text="Reshaping" FontWeight={"900"} FontSize={"3.5rem"} LineHeight={"1"} />
                    <Text text="the Future" FontWeight={"900"} FontSize={"3.5rem"} Color={"#808080"} LineHeight={"1"} />
                    <Text text="Fast Track IoT adoption" FontWeight={"500"} FontSize={"1.6rem"} Color={"#808080"} LineHeight={"1"}  Margin={"15px 0px 0px 0px"}/>

                </div>
        </div>

        <div className='DisruptTextContent'>
            <Text text="<span>8<sup>th</sup> August 2023 – Dubai, UAE and Rawalpindi, Pakistan </span>– Today marks a turning point for the digital
landscape of Pakistan as Disrupt-X, a leading developer and provider of Internet of Things (IoT)
solutions platform, and DynaSys Networks, a technology startup focused on driving innovation in
Product Development and Systems Integration declare their strategic partnership through a formal
agreement for the development of the Pakistan market for IoT products and services."/>

<Text Margin="30px 0px 0px 0px" text={"The development and launch of an integrated IoT platform with a full array of hosting solutions, transmission network, end user devices and use cases, will positively impact various sectors, including telecommunications, manufacturing, agriculture, healthcare, real estate, and government. This partnership strives to pave the way for enhanced productivity, workload and cost-efficiency, and the emergence of new business models ushering in the use of technology at an unprecedented scale in the country thus contributing to Pakistan's economic growth."} />

<Text Margin="30px 0px 30px 0px" text={"At the core of this partnership is the unique cost effective Disrupt-X IoT Platform, structured into interwoven layers that work seamlessly to provide a comprehensive solution. The foundation is formed by the IoT devices, followed by a communication layer offering diverse options such as Sigfox, LoRaWAN, cellular networks, or NB-IoT. These layers interact with a core platform that includes data sources, connectors, APIs, analytics, and AI engines. Together, they generate a unified dashboard, offering industry-specific insights, analytics, sensor data, device health status, and detailed reports, ensuring clients have all essential information at their fingertips for informed decision-making."} />
<Text text={" The CEO of Disrupt-X, Asim Sajwani, expressed excitement about the partnership, stating, “This collaboration with DynaSys Networks is a testament to our shared passion for innovation and our belief in Pakistan's potential as a hub for technological advancement.&quot;"} />

<Text Margin="30px 0px 0px 0px" text={"“DynaSys has a stated mission to become the largest IoT service provider in Pakistan. Today marks day one of our drive to unlock a new wave of innovation in this emerging technological landscape in the country. With our mantra of ideate, innovate, implement and in conjunction with Disrupt-X and other Pakistani technology partners we are geared up to fully develop the IoT ecosystem in the country” stated Ali Akhtar, DynaSys Networks’ CEO."} />


<Text Margin="50px 0px 0px 0px" FontWeight={"700"} text={"About Disrupt-X: "}/>

<Text Margin="30px 0px 0px 0px" text={"Disrupt-X, a Dubai-based company providing a full stack of End-to-End IoT Platform solution that is hardware & communications agnostic using Artificial Intelligence, Machine Learning, Web/Mobile App. Development, and Big Data. With over 55 ready use-cases on their marketplace, Disrupt-X plans to extend their portfolio to up to 80+ ready use-cases by the end of this year. The Disrupt-X platform offers full-stacked solutions which can be scaled from a single asset level to city level. The platform can be hosted in the Cloud, On-Premises or Hybrid. Disrupt-X delivers fully integrated end-to-end solutions, including Mobile Applications. The Disrupt-X IoT Platform enables public operators, businesses, and enterprises to oversee all integrated use-cases under one platform." } />

<Text Margin="50px 0px 0px 0px" FontWeight={"700"}  text={"About DynaSys Networks: "} />

<Text Margin="30px 0px 0px 0px" text={"DynaSys Networks is a technology startup based in Pakistan and the UAE, focused on driving innovation in Product Development and Systems Integration. DynaSys’ passion for Product Development encompasses IoT and Space, and the Systems Integration expertise covers Cybersecurity, Satellite Communications, Networking Solutions and Surveillance and Security Solutions."} />
        </div>
        <div className="Press_Release_bottom">
          <div className="DisruptSiteLinkSec">
            <Text
              text="For more details, please visit:<br>"
              Margin="0px 2px 0px 0px"
              FontSize="1.8rem"
            />
            <MyButton
              RouteLink="https://dynasysnetworks.com"
              FontSize="1.8rem"
              text="https://dynasysnetworks.com"
              FontWeight="400"
            />
            <MyButton
              RouteLink="https://disrupt-x.io"
              FontSize="1.8rem"
              text="https://disrupt-x.io"
              FontWeight="400"
            />
            


          </div>
        </div>
      </div>
    </div>
    
    </div>
      
      <div class="borderSec"><div class="border"></div></div>
        
    </div>
  )
}
