import React from "react";
import "../Styles/PressRelease.css";
import { Helmet } from "react-helmet";
import Navbar from "../../../Components/Navbar/Navbar";
import Text from "../../../Components/Text/Text";
import ImageComp from "../../../Components/ImageComp/ImageComp";
import MyButton from "../../../Components/Button/Button";
import Footer from "../../../Components/Footer/Footer";
import HeaderSection from "../../../Components/HeaderSection/HeaderSection";
import "../Styles/MousSigningCeremony.css";

export default function AzercosmosOfferIotSolution() {
  return (
    <div className="PressRelease">
      <Helmet>
        <title>Azercosmos Offer Iot Solution</title>
      </Helmet>
      
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            text="Media Center   <span>/</span>"
            text1="  Press Releases"
            RouteLink1="/press_releases"
            Color1="#6C6C6C"
            Color2="black"
          />
          <div className="PressReleaseBody">
          <div className="MousSigningCeremonyPress">
          <div className="PressReleaseBodyTopText">
              <Text
                text="Azercosmos to Offer IoT Solutions Across Its Satcom Network in Partnership with DynaSys Networks"
                FontWeight="900"
                FontSize={"3.5rem"}
                font
                Margin="50px 0px 0px 0px"
              />
              <Text
                text="Tue 09 July, 2024"
                FontWeight="500"
                Color="#808080"
                FontSize={"1.8rem"}
                font
                Margin="20px 0px 50px 0px"
              />
            </div>
            <div className="MousCeremonyContent">
              <Text  text="<span>Baku, Azerbaijan and Dubai, UAE – July 9, 2024   </span>  – Azerbaijan’ Space Agency - Azercosmos, today announced a strategic partnership with DynaSys Networks, a technology company with a cutting-edge Internet of Things ecosystem. " />
              <Text Margin="30px 0px 30px 0px" text=" Through this partnership, Azercosmos now has the capability to integrate both its own and its customers’ satellite network infrastructure with DynaSys’ all-encompassing IoT platform empowering businesses across the EMEA regions to manage and deploy innovative IoT applications. This relationship will empower businesses to gain access to cutting-edge IoT solutions that can improve operational efficiency, optimize resource management, and unlock new revenue streams, ultimately leading to a more connected and intelligent future. " />
              <Text   text="Within the frame of the agreement both parties will collaborate on identifying and developing tailored IoT solutions that address the specific needs and challenges faced by businesses in these markets with applications in smart cities, agriculture, asset tracking, fleet management, environmental sustainability, and business processes efficiency." />
              <Text Margin="30px 0px 30px 0px" text="This collaboration between Azercosmos and DynaSys also encompasses applications in Earth Observation."/>
               <Text text="Fuad Aslanov, the Vice-chairman of Azercosmos stated, “At Azercosmos, we are dedicated to utilizing our satellite communication infrastructure to foster innovation and economic development in the regions we serve. Our commitment to digitalization is strong, and by collaborating with DynaSys, a leader in IoT solutions, we can provide a wide range of services that extend beyond satellite connectivity.” "/>
              <Text Margin="30px 0px 30px 0px" text="Ali Akhtar, Founder and CEO, DynaSys Networks: “We are excited to partner with Azercosmos. This collaboration unlocks exciting opportunities to expand the reach of IoT applications to customers across three continents. Data driven decisions underscore the operations of modern businesses and we’re fully committed to supporting Azercosmos, through our IoT ecosystem, to efficiently use diverse data streams to benefit of their customer base.”" />
            </div>
            <div className="PressReleaseImage">
              <ImageComp Src="/Images/azercosmosPressReleaseImage.png" Width="100%" Margin="50px 0px 0px 0px" />
            </div>
            <div className="PressReleaseTextAfterImg">
              <Text Margin="50px 0px 20px 0px" FontWeight={"800"}  text="Azercomos Contact" />
              <Text text="Nigar Kazimova" />
              <div className="Azercosmos TextWithLink2">
              <Text text=" (Email)" Margin={"0px 1rem 0px 0rem"} />
              <MyButton
                  RouteLink="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=nigar.kazomova@azercomos@az"
                  FontSize="1.8rem"
                  text="nigar.kazomova@azercomos@az"
                  FontWeight="400"
                />
              </div>
              <div className="Azercosmos TextWithLink2">
              <Text text=" (Phone)" Margin={"0px 0.6rem 0px 0rem"} />
              <Text text="+99.455.353.099" />
              </div>

              <Text Margin="50px 0px 20px 0px" FontWeight={"800"}  text="DynaSys Networks Contact" />
              <Text text="Abdul Muizz" />
              <div className="Azercosmos TextWithLink2">
              <Text text=" (Email)" Margin={"0px 1rem 0px 0rem"} />
              <MyButton
                  RouteLink="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=nigar.abdul.muizz@dynasysnetworks.com"
                  FontSize="1.8rem"
                  text="abdul.muizz@dynasysnetworks.com"
                  FontWeight="400"
                />
              </div>
              <div className="Azercosmos TextWithLink2">
              <Text text=" (Phone)" Margin={"0px 0.6rem 0px 0rem"} />
              <Text text=" +92.317.508.1622" />
              </div>

              </div>
          </div>
            
          </div>
        </div>
      </div>

      <div class="borderSec">
        <div class="border"></div>
      </div>
        
    </div>
  );
}
