import React from "react";
import Navbar from "../../../Components/Navbar/Navbar";
import HeaderSection from "../../../Components/HeaderSection/HeaderSection";
import ImageComp from "../../../Components/ImageComp/ImageComp";

import "../Styles/PressReleaseWraper.css";
import Text from "../../../Components/Text/Text";
import MyButton from "../../../Components/Button/Button";
import Footer from "../../../Components/Footer/Footer";
import { Helmet } from "react-helmet";
import PressReleaseWrapperEach from "../Components/PressReleaseWrapperEach/PressReleaseWrapperEach";

export default function PressReleaseWraper() {
  const PressRelease = [
    // Azercosmos to Offer IoT Solutions Across Its Satcom Network..
    {
      PressReleaseImage: "/Images/azercosmosPaternship.png",
      PressReleaseDate: "Tue 09 July, 2024",
      PressReleaseHeading:
        "Azercosmos to Offer IoT Solutions Across Its Satcom Network in Partnership with DynaSys Networks",
      PressReleaseDetails:
        "DynaSys Networks, a technology company with a cutting-edge Internet of Things ecosystem. .",
      PressReleaseLink: "/press_releases/azercosmos_offer_iot_solution",
    },
    // DynaSys and TSGN Join Forces to Accelerate IoT Growth..
    {
      PressReleaseImage: "/Images/DynasysTsgn.png",
      PressReleaseDate: "Mon 03 June, 2024",
      PressReleaseHeading:
        "DynaSys and TSGN Join Forces to Accelerate IoT Growth in South East Asia",
      PressReleaseDetails:
        "DynaSys Networks, a leading provider of Internet of Things (IoT) solutions, and TS Global Network (TSGN), a prominent Malaysian origin regional satellite..",
      PressReleaseLink: "/press_releases/dynasys_tsgn_join_forces",
    },
    // DynaSys and Pronet ink MoU
    {
      PressReleaseImage: "/Images/dynasysPronet.png",
      PressReleaseDate: "Mon 20 May, 2024",
      PressReleaseHeading:
        "IoT Innovation in Pakistan Accelerated - DynaSys and Pronet ink MoU",
      PressReleaseDetails:
        "DynaSys Networks (DynaSys), a leader in the IoT space and Pronet, a renowned system integrator in Pakistan have signed a Memorandum of Understanding..",
      PressReleaseLink: "/press_releases/dynasys_pronet_ink_mou",
    },
    // Inotech and DynaSys Combine Forces to Commercialize IoT in Pakistan
    {
      PressReleaseImage: "/Images/inotechDynasysresReleaseWrapper.png",
      PressReleaseDate: "Tue 06 Feb, 2024",
      PressReleaseHeading:
        "Inotech and DynaSys Combine Forces to Commercialize IoT in Pakistan",
      PressReleaseDetails:
        "Inotech Solutions (Inotech) and DynaSys Networks (DynaSys) have signed a Memorandum of Understanding (MoU) during a ceremony held at the National Incubation Center..",
      PressReleaseLink: "/press_releases/inotech_dynasys",
    },
    // DynaSys Networks and Xiplink Awarded Cellular Backhaul..
    {
      PressReleaseImage: "/Images/dynasysXiplinkPressReleaseWrapperImage.png",
      PressReleaseDate: "Wed 11 Oct, 2023",
      PressReleaseHeading:
        "DynaSys Networks and Xiplink Awarded Cellular Backhaul Optimization Project in Central Asia",
      PressReleaseDetails:
        "XipLink Inc., the technology leader in Wireless Link Optimization, and DynaSys Networks, an up-and-coming Systems Integrator, are pleased to announce..",
      PressReleaseLink: "/press_releases/dynasys_xiplink",
    },
    //  PTCL & DynaSys Networks Join Hands
    {
      PressReleaseImage: "/Images/PtclDynasysPressReleaseWraperImg2.png",
      PressReleaseDate: "Tue 12 Sep, 2023",
      PressReleaseHeading:
        "PTCL & DynaSys Join Hands to Take IoT & Satellite Communications to the Next Level",
      PressReleaseDetails:
        "Pakistan Telecommunication Company Limited (PTCL) and DynaSys Networks (DynaSys) have signed two Memoranda of Understandings (MoUs)..",
      PressReleaseLink: "/press_releases/ptcl_dynasys",
    },
    //  DynaSys Networks receives IoT LPWAN License from Pakistan Telecommunication Authority
    {
      PressReleaseImage: "/Images/mousPressReleaseWrapper.webp",
      PressReleaseDate: "Wed 06 Sep, 2023",
      PressReleaseHeading:
        "DynaSys Networks receives IoT LPWAN License from Pakistan Telecommunication Authority",
      PressReleaseDetails:
        "DynaSys Networks announced today that the Pakistan Telecommunication Authority has issued an IoT LPWAN license to it for a 5-year period.",
      PressReleaseLink: "/press_releases/iot_license",
    },
    //  Imran Farooq Joins DynaSys Networks as Chief Technology Officer
    {
      PressReleaseImage: "/Images/ImranpressWraperImage.webp",
      PressReleaseDate: "Fri 25 Aug, 2023",
      PressReleaseHeading:
        "Imran Farooq Joins DynaSys Networks as Chief Technology Officer",
      PressReleaseDetails:
        "With over 19 years of in-depth IT, Telecoms and Satellite Communications experience, Imran Farooq provides a major boost to the technology team at DynaSys Networks.",
      PressReleaseLink: "/press_releases/imran_farooq_press_release",
    },
    //  Disrupt-X and DynaSys Networks Announce Innovative Partnership
    {
      PressReleaseImage: "/Images/disruptpresssmall.png",
      PressReleaseDate: "Tue 08 Aug, 2023",
      PressReleaseHeading:
        "Disrupt-X and DynaSys Networks Announce Innovative Partnership to Catalyze IoT Rollout in Pakistan",
      PressReleaseDetails:
        "Disrupt-X partners with DynaSys Networks to fast-track IoT adoption, marking a significant leap in Pakistan's digital landscape.",
      PressReleaseLink: "/press_releases/disrupt_x_press_release",
    },
    //  Hamid Nawaz Joins DynaSys Networks  as Chairman of the Board
    {
      PressReleaseImage: "/Images/press_inner_hamid.webp",
      PressReleaseDate: "Tue 25 Jul, 2023",
      PressReleaseHeading:
        "Hamid Nawaz Joins DynaSys Networks  as Chairman of the Board",
      PressReleaseDetails:
        "With over 26 years of global IT, Telecoms and Satellite Communications experience, Hamid Nawaz brings unique expertise, all-round high energy, and time-tested leadership to DynaSys Networks.",
      PressReleaseLink: "/press_releases/hamid_nawaz_press_release",
    },
  ];
  return (
    <div className="PressReleaseWraper">
      <Helmet>
        <title>Press Releases</title>
      </Helmet>
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            BannerText="Press Releases"
            Src="/Images/PressReleasebanner.webp"
            text="Media Center <span>/</span>"
            text1="Press Releases"
            RouteLink1="/press_releases"
            Color1="#6C6C6C"
            Color2="black"
          />
          <div className="leadershipdetails">
            <Text text="Press Releases" FontSize="3rem" FontWeight="700" />
          </div>
          <div className="press_release_container">
            {PressRelease.map((item) => (
              <PressReleaseWrapperEach
                Src={item.PressReleaseImage}
                PressDate={item.PressReleaseDate}
                PressHead={item.PressReleaseHeading}
                PressDetails={item.PressReleaseDetails}
                PressLink={item.PressReleaseLink}
              />
            ))}

          </div>
        </div>
      </div>
      <div className="borderSec">
        <div className="border"></div>
      </div>

        
    </div>
  );
}
