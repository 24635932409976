import React, { useState } from "react";
import { Helmet } from "react-helmet";
import HeaderSection from "../../Components/HeaderSection/HeaderSection";
import "./CareerOpenings.css";
import Text from "../../Components/Text/Text";
import MyButton from "../../Components/Button/Button";
import Collapsible from "react-collapsible";
import ThreeColumnWrapper from "../../Components/ThreeColumnWrapper/ThreeColumnWrapper";
import SolutionSliderInnerPages from "../../Components/SolutionSliderInnerPages/SolutionSliderInnerPages";
import PageDescription from "../../Components/PageDescription/PageDescription";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

const NewOpening = [
  {
    openingName: "Product Specialist – IoT",
    openingDate: "14 May 2024",
    openingType: "Permanent",
    openingLocation: "Islamabad, Karachi",
    openingStatus: "closed",
    openingDetails:
      "The Product Specialist - IoT will play a multifaceted role, responsible for designing IoT solutions according to customer requirements, researching new solution for existing and new use cases within the DynaSys IoT ecosystem, providing technical support to the sales team & generating comprehensive technical documentation.",
    openingResponsibilites: [
      "Develop a deep understanding of DynaSys’ IoT product portfolio, including the range of IoT applications and solutions offered.",
      "Stay updated with the latest advancements in IoT technology and industry trends to effectively develop and communicate the value proposition of our products to customers.",
      "Collaborate with customers to understand their specific requirements, challenges, and business objectives. Provide technical guidance and support to address customer inquiries, troubleshoot issues, and ensure successful implementation and adoption of our IoT solutions.",
      "Work closely with the sales teams to provide technical pre-sales support. Conduct in-depth product demonstrations and presentations to potential customers, showcasing the features, capabilities, and benefits of our IoT applications and solutions. Help the sales teams effectively position our products in the market.",
      "Develop a comprehensive understanding of the supported ecosystem of hardware and software that integrates with DynaSys’ IoT Platform. Collaborate with internal teams, including engineering and product management, to provide feedback on product enhancements, ecosystem integrations, and identify potential partnerships.",
      "Create and maintain technical documentation, including product guides, user manuals, and FAQs, to support customers and internal stakeholders. Deliver training sessions to customers and internal teams to ensure a clear understanding of our products and their functionalities.",
      "Design and implement customized IoT solutions that meet client specifications and requirements. Collaborate with the engineering team to ensure seamless integration and deployment of these solutions.",
      "Develop pricing strategies for all use cases on our IoT Platform.",
    ],
    openingRequirements: [
      "Develop a deep understanding of DynaSys’ IoT product portfolio, including the range of IoT applications and solutions offered.",
      "Stay updated with the latest advancements in IoT technology and industry trends to effectively develop and communicate the value proposition of our products to customers.",
      "Collaborate with customers to understand their specific requirements, challenges, and business objectives. Provide technical guidance and support to address customer inquiries, troubleshoot issues, and ensure successful implementation and adoption of our IoT solutions.",
      "Work closely with the sales teams to provide technical pre-sales support. Conduct in-depth product demonstrations and presentations to potential customers, showcasing the features, capabilities, and benefits of our IoT applications and solutions. Help the sales teams effectively position our products in the market.",
      "Develop a comprehensive understanding of the supported ecosystem of hardware and software that integrates with DynaSys’ IoT Platform. Collaborate with internal teams, including engineering and product management, to provide feedback on product enhancements, ecosystem integrations, and identify potential partnerships.",
      "Create and maintain technical documentation, including product guides, user manuals, and FAQs, to support customers and internal stakeholders. Deliver training sessions to customers and internal teams to ensure a clear understanding of our products and their functionalities.",
      "Design and implement customized IoT solutions that meet client specifications and requirements. Collaborate with the engineering team to ensure seamless integration and deployment of these solutions.",
      "Develop pricing strategies for all use cases on our IoT Platform.",
    ],
  },
];

export default function CareerOpenings() {
  return (
    <>
      <div className="CareerOpenings">
        <Helmet>
          <title>Available Positions</title>
        </Helmet>
        <HeaderSection
          BannerText=""
          Src="/Images/careerbanner.png"
          text="Careers <span>/</span>"
          text1="Available Positions"
          RouteLink="/career"
          RouteLink1="/"
          Color1="#6C6C6C"
          Color2="black"
        />
        <PageDescription
          text="Available<br>Positions"
          text1="At DynaSys Networks, our current vacancies offer compelling opportunities for exceptional individuals to join our dynamic team. We seek passionate professionals who excel in innovative, collaborative environments. Join us in shaping technology's future."
        />
        {NewOpening.map((item) => (
          <>
            <div className="NewOpeningsTopper">
              <div className="NewOpeningName">
                <div>
                  <Text
                    text={item.openingName}
                    FontSize={"2.6rem"}
                    FontWeight={"700"}
                    Color={"#3D3D3D"}
                  />
                  <Text
                    text={item.openingDate}
                    FontSize={"1.6rem"}
                    FontWeight={"500"}
                    Color={"#3D3D3D"}
                  />
                </div>

                <div className="NewOpeningButtons">
                  <Text
                    text={item.openingType}
                    BgColor={"#212121"}
                    Color={"white"}
                    Padding={"6px 3%"}
                    Radius={"25px"}
                  />
                  <Text
                    text={item.openingLocation}
                    Border={"1px solid black"}
                    Radius={"25px"}
                    Padding={"6px 3%"}
                  />
                </div>
              </div>
              <Text Margin={"5rem 0rem"} text={item.openingDetails} />
            </div>

            <div className="OpeningRequirements">
              <Collapsible trigger="Key Responsibilities:">
                {item.openingResponsibilites.map((responsibility, idx) => (
                  <Text text={responsibility} FontSize={"1.5rem"} />
                ))}
              </Collapsible>
              <Collapsible trigger="Position Requirements:">
                {item.openingRequirements.map((responsibility, idx) => (
                  <Text text={responsibility} FontSize={"1.5rem"} />
                ))}
              </Collapsible>
              <div className="Desktop">
                {item.openingStatus == "closed" ? (
                  <button disabled>
                    <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=hr@dynasysnetworks.com&su=Job%20Application%20(Please%20Mention%20the%20Position%20you%20are%20applying%20for)&body=Dear%20HR,%0A%0ACV%20attachment%20is%20compulsory">
                      Position Closed
                    </a>
                  </button>
                ) : (
                  <button>
                    <a target="blank" href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=hr@dynasysnetworks.com&su=Job%20Application%20(Please%20Mention%20the%20Position%20you%20are%20applying%20for)&body=Dear%20HR,%0A%0ACV%20attachment%20is%20compulsory">
                      Apply
                    </a>
                  </button>
                )}
              </div>
              <div className="Mobile">
                {item.openingStatus ? (
                  <button disabled>
                    <a href="mailto:hr@dynasysnetworks.com?subject=Job%20Application%20(Please%20Mention%20the%20Position%20you%20are%20applying%20for)&body=Dear%20HR,%0A%0ACV%20attachment%20is%20compulsory">
                    Position Closed
                    </a>
                  </button>
                ) : (
                  <button>
                    <a href="mailto:hr@dynasysnetworks.com?subject=Job%20Application%20(Please%20Mention%20the%20Position%20you%20are%20applying%20for)&body=Dear%20HR,%0A%0ACV%20attachment%20is%20compulsory">
                    Apply
                    </a>
                  </button>
                )}
              </div>
            </div>
          </>
        ))}
        {/* {applyForm ? (
          <div className="ApplyFormModal">
            <div className="ApplyForm">
            <Text text={"Welcome to DynaSys Networks"} FontSize={"1.4rem"} />
            <form action="Post">
              <label for="name">Name:</label>
              <input type="text" />
              <label for="email">Email:</label>
              <input type="email" />
              <label for="contact">Contact Number:</label>
              <input type="number" />
              <label for="resume">Resume:</label>
              <input type="file" />

            </form>
            </div>
          </div>
        ) : (
          ""
        )} */}

        <ThreeColumnWrapper
          Col1Text={
            "<span>Our Vision</span> <br> <br> We strive to establish ourselves as a preeminent force in technological innovation worldwide.<br><br>Empower organizations and individuals globally in an interconnected future.<br><br>Develop advanced solutions to catalyze positive transformations, foster sustainable growth and enrich worldwide quality of life."
          }
          Col2Text={
            "<span>Our Mission</span> <br> <br> Deliver innovative technology solutions that surpass customer expectations, drive business success, and create societal value.<br><br>Push the boundaries of what's possible, leveraging expertise in IoT, space technology, cybersecurity, and networking to solve complex challenges and shape technology's future."
          }
        />
        {/* Solution Slider */}
        <div className="SolutionSlider">
          <SolutionSliderInnerPages />
        </div>
      </div>
      <div className="borderSec">
        <div className="border"></div>
      </div>
    </>
  );
}
