import React from "react";
import "../Styles/PressRelease.css";
import { Helmet } from "react-helmet";
import Navbar from "../../../Components/Navbar/Navbar";
import Text from "../../../Components/Text/Text";
import ImageComp from "../../../Components/ImageComp/ImageComp";
import MyButton from "../../../Components/Button/Button";
import Footer from "../../../Components/Footer/Footer";
import HeaderSection from "../../../Components/HeaderSection/HeaderSection";
import "../Styles/MousSigningCeremony.css";

export default function DynasysTsgnJoinForces() {
  return (
    <div className="PressRelease">
      <Helmet>
        <title>Dynasys TSGN Join Forces</title>
      </Helmet>

      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            text="Media Center   <span>/</span>"
            text1="  Press Releases"
            // RouteLink="/press_releases/hamid_nawaz_press_release"
            RouteLink1="/press_releases"
            Color1="#6C6C6C"
            Color2="black"
          />
          <div className="PressReleaseBody">
            <div className="MousSigningCeremonyPress">
              <div className="PressReleaseBodyTopText">
                <Text
                  text="DynaSys and TSGN Join Forces to Accelerate IoT Growth in South East Asia"
                  FontWeight="900"
                  FontSize={"3.5rem"}
                  Margin="50px 0px 0px 0px"
                />
                <Text
                  text="Mon 03 June, 2024"
                  FontWeight="500"
                  Color="#808080"
                  FontSize={"1.8rem"}
                  font
                  Margin="20px 0px 50px 0px"
                />
              </div>
              <div className="MousCeremonyContent">
                <Text text="<span>Dubai, UAE and Cyberjaya, Malaysia; June 3, 2024</span> – DynaSys Networks, a leading provider of Internet of Things (IoT) solutions, and TS Global Network (TSGN), a prominent Malaysian origin regional satellite communications teleport operator and service provider, announced today their partnership to collaborate and develop the IoT market in South East Asia and the broader territories covered by TSGN’s connectivity services. This strategic partnership signifies a significant step forward for both companies, fostering innovation and empowering businesses to harness the transformative power of IoT." />
                <Text
                  Margin="30px 0px 30px 0px"
                  text="Through this agreement DynaSys' powerful IoT platform called ALEF and the use cases available on it will be integrated with TSGN's existing infrastructure, specifically their satellite transmission network. The cooperation also encompasses joint efforts to identify and deploy Low Power Wide Area Networks (LPWANs) such as LoRaWAN for further strengthening the region's IoT foundation."
                />
                <Text
                  Margin="30px 0px 30px 0px"
                  text="“This strategic partnership is a significant step forward for DynaSys as we expand our footprint in South East Asia,” said Ali Akhtar, Founder and CEO of DynaSys Networks. “By combining our expertise with TSGN's established presence in the region, both organizations are well-positioned to unlock the immense potential of the IoT market in the region. Along with TSGN, we will focus on identifying and implementing revenue generation models that ensure a sustainable coupling of IoT and satellite communications.”"
                />
                <Text
                  Margin="30px 0px 30px 0px"
                  text="“As the world is progressing towards data driven decision making, it is the right time for us at TSGN to incorporate IoT solutions on top of our existing satellite communications connectivity services to bring a fuller and wholesome experience to our customers,” declared Hisham Ghaffar, Group CEO of TSGN. “The combination of DynaSys’ sophisticated suite of IoT capabilities and our extensive satellite communications infrastructure, will provide businesses in Malaysia and the ASEAN region with a comprehensive and best-in-class IoT solution enabling TSGN to continue evolving from a connectivity centric service provider to a full-fledged solutions provider.”"
                />
                <Text
                  Margin="30px 0px 30px 0px"
                  text="This collaboration between DynaSys Networks and TSGN is a testament to the growing importance of IoT in South East Asia. By joining forces, these companies aim to empower businesses across the region to embrace the opportunities presented by IoT and contribute to a more connected and intelligent future."
                />
              </div>
              <div className="MousCeremonyPressImage">
                <ImageComp
                  Src="/Images/DynasysTsgnPageImage.png"
                  Width="100%"
                  Margin="50px 0px 0px 0px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="borderSec">
        <div class="border"></div>
      </div>
    </div>
  );
}
