import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./Pages/Landing/Landing";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Iot from "./Pages/Iot/Iot";
import Satellites from "./Pages/Satellites/Satellites";
import Cybersecurity from "./Pages/Cybersecurity/Cybersecurity";
import Networks from "./Pages/Networks/Networks";
import Surveillance from "./Pages/Surveillance/Surveillance";
import PowerSolutions from "./Pages/PowerSolutions/PowerSolutions";
import Professionalservices from "./Pages/Professional services/Professional services";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Career from "./Pages/Career/Career";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import MobMenu from "./Components/MobMenu/MobMenu";
import PreReleaseWraper from "./Modules/PressReleases/Pages/PressReleaseWraper";
import BoardChairman from "./Modules/LeaderShip/Pages/BoardChairman";
import LeasderShipWraper from "./Modules/LeaderShip/Pages/LeasderShipWraper";
import DisruptXPressRelease from "./Modules/PressReleases/Pages/DisruptXPressRelease";
import ImranFarooqPressRelease from "./Modules/PressReleases/Pages/ImranFarooqPressRelease";
import HamidNawazPressRelease from "./Modules/PressReleases/Pages/HamidNawazPressRelease";
import ImranFarooq from "./Modules/LeaderShip/Pages/ImranFarooqCTO";
import AliCEO from "./Modules/LeaderShip/Pages/AliCEO";
import MousSigningCeremony from "./Modules/PressReleases/Pages/MousSigningCeremony";
import PtclDynasysJoinHands from "./Modules/PressReleases/Pages/PtclDynasysJoinHands";
import DynasysXiplink from "./Modules/PressReleases/Pages/DynasysXiplink";
import InotechAndDynasysCombine from "./Modules/PressReleases/Pages/InotechAndDynasysCombine";
import IotNew from "./Pages/Iot/IotNew";
import DynasysPronetInk from "./Modules/PressReleases/Pages/DynasysPronetInk";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import CareerOpenings from "./Pages/CareerOpenings/CareerOpenings";
import DynasysTsgnJoinForces from "./Modules/PressReleases/Pages/DynasysTsgnJoinForces";
import AzercosmosOfferIotSolution from "./Modules/PressReleases/Pages/AzercosmosOfferIotSolution";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/whoweare" element={<AboutUs />} />
          <Route path="/iot" element={<IotNew />} />
          <Route path="/satellites" element={<Satellites />} />
          <Route path="/cybersecurity" element={<Cybersecurity />} />
          <Route path="/networks" element={<Networks />} />
          <Route path="/surveillance" element={<Surveillance />} />
          <Route path="/powersolutions" element={<PowerSolutions />} />
          <Route
            path="/professionalservices"
            element={<Professionalservices />}
          />

          {/* Press Releases Routes */}
          <Route path="/press_releases" element={<PreReleaseWraper />} />
          <Route
            path="/press_releases/hamid_nawaz_press_release"
            element={<HamidNawazPressRelease />}
          />
          <Route
            path="/press_releases/disrupt_x_press_release"
            element={<DisruptXPressRelease />}
          />
          <Route
            path="/hamid_nawaz_press_release"
            element={<HamidNawazPressRelease />}
          />
          <Route
            path="/disrupt_x_press_release"
            element={<DisruptXPressRelease />}
          />
          <Route
            path="/imran_farooq_press_release"
            element={<ImranFarooqPressRelease />}
          />
          <Route
            path="/press_releases/imran_farooq_press_release"
            element={<ImranFarooqPressRelease />}
          />

          {/* DynaSys Networks receives IoT LPWAN License */}

          <Route path="/iot_license" element={<MousSigningCeremony />} />
          <Route
            path="/press_releases/iot_license"
            element={<MousSigningCeremony />}
          />

          {/* PTCL & DynaSys Networks Join Hands */}

          <Route path="/ptcl_dynasys" element={<PtclDynasysJoinHands />} />
          <Route
            path="/press_releases/ptcl_dynasys"
            element={<PtclDynasysJoinHands />}
          />

          {/* DynaSys Networks and Xiplink Awarded Cellular Backhaul Optimization Project in Central Asia */}
          <Route path="/dynasys_xiplink" element={<DynasysXiplink />} />
          <Route
            path="/press_releases/dynasys_xiplink"
            element={<DynasysXiplink />}
          />

          {/* Inotech & DynaSys Combine Forces to Commercialize IoT in Pakistan */}

          <Route
            path="/inotech_dynasys"
            element={<InotechAndDynasysCombine />}
          />
          <Route
            path="/press_releases/inotech_dynasys"
            element={<InotechAndDynasysCombine />}
          />

          {/* IoT Innovation in Pakistan Accelerated – DynaSys and Pronet ink MoU */}

          <Route
            path="/dynasys_pronet_ink_mou"
            element={<DynasysPronetInk />}
          />
          <Route
            path="/press_releases/dynasys_pronet_ink_mou"
            element={<DynasysPronetInk />}
          />

          {/* // DynaSys and TSGN Join Forces to Accelerate IoT Growth.. */}

          <Route
            path="/dynasys_tsgn_join_forces"
            element={<DynasysTsgnJoinForces />}
          />
          <Route
            path="/press_releases/dynasys_tsgn_join_forces"
            element={<DynasysTsgnJoinForces />}
          />

          {/*     // Azercosmos to Offer IoT Solutions Across Its Satcom Network.. */}

          <Route
            path="/azercosmos_offer_iot_solution"
            element={<AzercosmosOfferIotSolution />}
          />
          <Route
            path="/press_releases/azercosmos_offer_iot_solution"
            element={<AzercosmosOfferIotSolution />}
          />



          {/* LeaderShip routes */}
          <Route path="/leadership" element={<LeasderShipWraper />} />
          <Route path="/founder&ceo" element={<AliCEO />} />
          <Route path="/board_chairman" element={<BoardChairman />} />
          <Route path="/chief_technology_officer" element={<ImranFarooq />} />

          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/careers" element={<Career />} />
          <Route path="/available_positions" element={<CareerOpenings />} />

          <Route path="/mobmenu" element={<MobMenu />} />
          {/* <Route path="/videowhite" element={<LandingVideo />} /> */}
          {/* <Route path="/videoblack" element={<LandingVideoblack />} /> */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
