import React from "react";
import HeaderSection from "../../Components/HeaderSection/HeaderSection";
import PageDescription from "../../Components/PageDescription/PageDescription";
import PageDescription2 from "../../Components/PageDescription2/PageDescription2";
import Footer from "../../Components/Footer/Footer";
import SolutionSliderInnerPages from "../../Components/SolutionSliderInnerPages/SolutionSliderInnerPages";
import Navbar from "../../Components/Navbar/Navbar";
import { Helmet } from "react-helmet";

export default function Networks() {
  return (
    <div className="Cybersecurity">
      <Helmet>
        <title>Networking Solutions</title>
      </Helmet>
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            BannerText="Networking<br> Solutions  "
            Src="/Images/NetworksBAnner.webp"
            text="Solutions <span>/</span>"
            text1="Networking Solutions"
            RouteLink="/#SolutionSlider"
            RouteLink1="/networks"
            Color1="#6C6C6C"
            Color2="black"
          />

          <PageDescription
            text="Networking<br>
      Solutions"
            text1="Gain full visibility and control over your network operations with our OSI L-2 & L-3 Solutions. Our comprehensive reporting tools offer a complete view of your network. With our network solutions, you can efficiently manage and optimize your network infrastructure, ensuring seamless connectivity and operational efficiency."
          />

          <PageDescription2
            Src="/Images/networksDivider.webp"
            text="Our Expertise and Support"
            text1="Our team of dedicated professionals is committed to providing reliable and secure network solutions that align with your business objectives. <br><br> With our guidance, you can navigate the complex world of networking with confidence and achieve greater success.<br><br> Trust us as your network partner, and experience the benefits of streamlined operations, enhanced connectivity, and improved productivity."
            Src1="/Images/networks2.webp"
          />

          {/* Solution Slider */}
          <div className="SolutionSlider">
            <SolutionSliderInnerPages />
          </div>
        </div>
      </div>

      <div className="borderSec">
        <div className="border"></div>
      </div>
    </div>
  );
}
