import React from "react";
import "../Styles/PressRelease.css";
import { Helmet } from "react-helmet";
import Navbar from "../../../Components/Navbar/Navbar";
import Text from "../../../Components/Text/Text";
import ImageComp from "../../../Components/ImageComp/ImageComp";
import MyButton from "../../../Components/Button/Button";
import Footer from "../../../Components/Footer/Footer";
import HeaderSection from "../../../Components/HeaderSection/HeaderSection";
import "../Styles/MousSigningCeremony.css";

export default function DynasysPronetInk() {
  return (
    <div className="PressRelease">
      <Helmet>
        <title>DynaSys and Pronet ink MoU</title>
      </Helmet>

      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            text="Media Center   <span>/</span>"
            text1="  Press Releases"
            // RouteLink="/press_releases/hamid_nawaz_press_release"
            RouteLink1="/press_releases"
            Color1="#6C6C6C"
            Color2="black"
          />
          <div className="PressReleaseBody">
            <div className="MousSigningCeremonyPress">
              <div className="PressReleaseBodyTopText">
                <Text
                  text="IoT Innovation in Pakistan Accelerated – DynaSys and Pronet ink MoU"
                  FontWeight="900"
                  FontSize={"3.5rem"}
                  Margin="50px 0px 0px 0px"
                />
                <Text
                  text="Mon 20 May, 2024"
                  FontWeight="500"
                  Color="#808080"
                  FontSize={"1.8rem"}
                  font
                  Margin="20px 0px 50px 0px"
                />
              </div>
              <div className="MousCeremonyContent">
                <Text text="<span>Karachi, Pakistan, May 20, 2024:</span> DynaSys Networks (DynaSys), a leader in the IoT space and Pronet, a renowned system integrator in Pakistan have signed a Memorandum of Understanding (MoU) on IoT during a ceremony held at the National Aerospace Science and Technology Park in Karachi, Pakistan." />
                <Text
                  Margin="30px 0px 30px 0px"
                  text="The signing ceremony was jointly chaired by Abdullah Masood Khan, Director, Pronet and Ali Akhtar, Founder and CEO, DynaSys Networks and was attended by senior officials from both organizations."
                />
                <Text
                  Margin="30px 0px 30px 0px"
                  text="Through this MoU, DynaSys and Pronet aim to combine their expertise, resources, and infrastructure to spearhead the education and adoption of IoT use cases in Pakistan. By leveraging DynaSys’ cutting-edge technologies and Pronets depth in systems integration and service provisioning, this partnership aspires to implement innovative solutions that address pressing challenges and unlock new opportunities in the digital landscape of the country underscored by data driven decision-making."
                />
                <Text
                  Margin="30px 0px 30px 0px"
                  text="During the ceremony, Pronet’s Abdullah Masood Khan said, “By fostering a culture of collaboration and co-creation, we are confident that our partnership will yield groundbreaking innovations that have the power to reshape industries, enhance quality of life, and drive sustainable development. Together, we are committed to shaping the future of IoT and paving the way for a more connected, intelligent, and prosperous Pakistan.”"
                />
                <Text
                  Margin="30px 0px 30px 0px"
                  text="On the occasion, DynaSys’ Ali Akhtar, stated, “The collaboration between DynaSys and Pronet signifies a shared commitment to harnessing the transformative potential of IoT technologies to drive efficiency, connectivity, and sustainability across various sectors. With ALEF, our powerful IoT platform, driving our innovation, we are well positioned to digitalize entire industries, businesses, and everyday life.”"
                />
              </div>
              <div className="MousCeremonyPressImage">
                <ImageComp
                  Src="/Images/DynasysPronetPageImage.png"
                  Width="auto"
                  Margin="50px 0px 0px 0px"
                />
                <div className="MousCeremonyPressImageCaption inotechCap">
                  <Text
                    Margin="50px 0px 30px 0px"
                    FontSize="1.2rem"
                    text="Seated: Ali Akhtar, CEO of DynaSys Networks (Right), Abdullah Masood Khan, Director of Pronet (Left) accompanied by officials of Dynasys and Pronet."
                  />
                </div>
              </div>

              <div className="Press_Release_bottom">
                <div className="DynasysPortnet SiteLinkSec">
                  <Text
                    text="For any inquiries, please reach out at:"
                    Margin="0px 2px 0px 0px"
                    FontSize="1.8rem"
                  />
                  <ul>
                    <li>
                    <Text
                    text="DynaSys Networks: "
                    Margin="0px 2px 0px 0px"
                    FontSize="1.8rem"
                  />
                      <MyButton
                        RouteLink="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@dynasysnetworks.com"
                        FontSize="1.8rem"
                        text="info@dynasysnetworks.com"
                        FontWeight="400"
                      />
                    </li>
                    <li>
                    <Text
                    text="Pronet Private Limited:"
                    Margin="0px 2px 0px 0px"
                    FontSize="1.8rem"
                  />
                      <MyButton
                        RouteLink="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@pronet-tech.net"
                        FontSize="1.8rem"
                        text="info@pronet-tech.net"
                        FontWeight="400"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="borderSec">
        <div class="border"></div>
      </div>
        
    </div>
  );
}
