import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import Text from "../Text/Text";
import SolutionSliderEachItem from "../SolutionSliderEachItem/SolutionSliderEachItem";

// Learning From Cryptopedia Slider

const SwiperCarousel2 = () => {
  return (
    <div className="LandingpageCrypSlider">
      <Swiper
        className=" CryptopediaSider"
        modules={[Navigation, Scrollbar, A11y]}
        spaceBetween={30}
        slidesPerView={6.5}
        navigation
        breakpoints={{
          // Responsive breakpoints
          150: {
            slidesPerView: 2, // Number of slides to show on mobile
            spaceBetween: 25, // Space between slides on mobile
          },
          767: {
            slidesPerView: 6.5, // Number of slides to show on mobile
            spaceBetween: 15, // Space between slides on mobile
          },
        }}
        pagination={{
          spaceBetween: 70,
        }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <SolutionSliderEachItem
            RouterLink="/Iot"
            Src="/Images/Iot.webp"
            text="Internet of Things"
          />
        </SwiperSlide>

        <SwiperSlide>
          <SolutionSliderEachItem
            RouterLink="/satellites"
            Src="/Images/Communication.webp"
            text="Satellite Communications"
          />
        </SwiperSlide>

        <SwiperSlide>
          <SolutionSliderEachItem
            RouterLink="/cybersecurity"
            Src="/Images/cyberSecurity.webp"
            text="Cybersecurity"
          />
        </SwiperSlide>

        <SwiperSlide>
          <SolutionSliderEachItem
            RouterLink="/networks"
            Src="/Images/networks.webp"
            text="Networking Solutions"
          />
        </SwiperSlide>

        <SwiperSlide>
          <SolutionSliderEachItem
            RouterLink="/surveillance"
            Src="/Images/Surveillance.png"
            text="Surveillance"
          />

          <style>
            {`.swiper-pagination{
      color: red;
    },
    .swiper-pagination-bullet {
      color: red;
      background-color: red;
    }
    `}
          </style>
        </SwiperSlide>
        <SwiperSlide>
          <SolutionSliderEachItem
            RouterLink="/powersolutions"
            Src="/Images/PowerSolutions.png"
            text="Power Solutions"
          />
        </SwiperSlide>

        <SwiperSlide>
          <SolutionSliderEachItem
            RouterLink="/professionalservices"
            Src="/Images/ProfessionalServices.png"
            text="Professional Services"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default function SolutionSliderInnerPages() {
  return (
    <div className="LearningFromCryptopedia">
      {/* ************************************************************
       ******* Learning from Cryptopedia Heading SECTION ******
       *****************************************************************/}

      <div className="MediaContentHeading">
        <Text
          text="Solutions"
          FontSize="2.5rem"
          FontWeight="500"
          Margin="50px 0px 35px 0px"
        />
      </div>
      <SwiperCarousel2 />
    </div>
  );
}
